<template>
  <div>
    <KTCodePreview v-bind:title="'Danh sách phiếu nhập kho'">
      <template v-slot:toolbar>
        <div class="row">
          <div>
            <b-dropdown size="sm" id="dropdown-1" right>
              <template slot="button-content">
                <i style="font-size: 1rem" class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="reportClick">
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div v-if="checkPermission('STOCK_INSERT')" class="ml-3">
            <router-link to="/stocks/add-stock">
              <b-button
                v-bind:style="btnCreate"
                variant="primary"
                size="sm"
                class="font-weight-bolder"
              >
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
              </b-button>
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col md="2">
            <b-form-select
              size="sm"
              v-model="selectedCompany"
              :options="listCompany"
              value-field="id"
              text-field="name"
              v-on:change="onChangeCompany"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled
                  >-- Doanh nghiệp --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
          <b-col md="3">
            <multiselect
              v-model="valueStore"
              :options="optionsStore"
              :multiple="true"
              track-by="code"
              :close-on-select="false"
              @select="onSelectStore($event)"
              @remove="onRemoveStore($event)"
              :show-labels="false"
              :showNoResults="false"
              :showPointer="false"
              :custom-label="customLabelStore"
              placeholder="Chọn cửa hàng"
            >
              <template slot="tag">{{ '' }}</template>
              <template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ valueStore.length }} cửa hàng đã chọn</span
                ></template
              >
              <template v-slot:no-result> Danh sách cửa hàng rỗng! </template>
              <span
                class="checkbox-label"
                slot="option"
                slot-scope="scope"
                @click.self="select(scope.option)"
              >
                {{ scope.option.name }}
                <input
                  class="test"
                  type="checkbox"
                  v-model="scope.option.checked"
                  @focus.prevent
                />
              </span>
              <span
                class="checkbox-label"
                style="font-size: 12px"
                slot="noResult"
              >
                Không có kết quả
              </span>
            </multiselect>
          </b-col>
          <b-col md="3">
            <vue-autosuggest
              v-model="searchProvider"
              :suggestions="filteredOptionProvider"
              @selected="onSelectedProvider"
              :limit="10"
              @input="onInputChangeProvider"
              :input-props="{
                id: 'autosuggest__input',
                placeholder: 'NCC',
              }"
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                {{ suggestion.item.name }}
              </div></vue-autosuggest
            >
          </b-col>
          <b-col md="2">
            <b-form-select
              size="sm"
              v-model="selectedType"
              :options="listType"
              value-field="id"
              text-field="name"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled
                  >-- Kiểu nhập kho --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="2">
            <b-form-input
              v-model="searchId"
              type="text"
              placeholder="Mã phiếu"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col md="3">
            <b-form-input
              v-model="search"
              type="text"
              placeholder="Tìm kiếm theo nội dung"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col md="3">
            <vue-autosuggest
              v-model="searchProduct"
              :suggestions="filteredOptionsProduct"
              @selected="onSelectedProduct"
              :limit="10"
              @input="onInputChangeProduct"
              :get-suggestion-value="getSuggestionValue"
              :input-props="{
                id: 'autosuggest__input',
                placeholder: 'Nhập mã, tên, mã vạch sản phẩm',
              }"
              :should-render-suggestions="
                (size, loading) => size >= 0 && !loading && searchProduct !== ''
              "
              ><div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                {{ suggestion.item.productName }}
              </div>
            </vue-autosuggest>
          </b-col>
          <b-col md="2">
            <date-picker
              placeholder="Từ ngày"
              class="form-control-sm"
              :config="dpConfigs.start"
              v-model="dpForm.startDate"
            ></date-picker>
          </b-col>
          <b-col md="2">
            <date-picker
              placeholder="Đến ngày"
              class="form-control-sm"
              :config="dpConfigs.end"
              v-model="dpForm.endDate"
            ></date-picker>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="stocks"
          :fields="fields"
          sort-by="count"
          class="table-bordered table-hover col-md-12"
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>

          <template v-slot:cell(count)="row">
            <div style="text-align: center">
              <span v-text="row.item.count" class="text-right"></span>
            </div>
          </template>

          <template v-slot:cell(code)="row">
            <div class="productCode" style="cursor: pointer">
              <a
                class="font-weight-bold"
                style="color: black"
                v-bind:href="getLinkDoc(row.item)"
                target="_blank"
              >
                {{ row.item.code }}
              </a>
            </div>
            <div v-if="row.item.idNhanh" class="text-primary">
              <span>ID Nhanh:</span>
              <span style="font-size: 11px">( {{ row.item.idNhanh }} )</span>
            </div>
            <p class="text-primary">{{ row.item.createdAt }}</p>
          </template>

          <template v-slot:cell(relateStockSlipId)="row">
            <div
              class="productCode"
              @click="linkToBill(row.item)"
              style="cursor: pointer"
            >
              <span v-text="row.item.relateStockSlipId"></span>
            </div>
          </template>

          <template v-slot:cell(providerName)="row">
            <div style="text-align: left">
              <span
                v-text="row.item.providerName"
                class="text-right text-primary mb-0"
              ></span>
            </div>
          </template>
          <template v-slot:cell(sourceWarehouseId)="row">
            <span
              v-text="row.item.sourceWarehouseId"
              class="font-weight-bolder"
            ></span>
            <br />
            <span v-if="row.item.companyName"
              >( {{ row.item.companyName }} )</span
            >
          </template>
          <template v-slot:cell(description)="row">
            <div
              @mouseover="hoverPrice = true"
              @mouseleave="hoverPrice = false"
            >
              <span v-text="row.item.description" class="mr-2"></span>
              <span @click="handleShowNoteModel(row.item)">
                <i v-if="hoverPrice" class="fas fa-edit"></i>
              </span>
            </div>
          </template>
          <template v-slot:cell(countProduct)="row">
            <div style="text-align: right">
              <span
                v-text="formatMoney(row.item.countProduct)"
                class="text-right"
              ></span>
            </div>
          </template>
          <template v-slot:cell(countQuantity)="row">
            <div style="text-align: right">
              <span
                v-text="formatMoney(row.item.countQuantity)"
                class="text-right"
              ></span>
            </div>
          </template>

          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="printItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-printer"></i>
                    &nbsp; In phiếu
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="editItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-if="checkPermission('STOCK_DELETE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng:
              {{ formatMoney(totalItems) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              v-model="page"
              class="customPagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchStock"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
        <template>
          <b-modal
            v-model="showEditPriceModal"
            hide-footer
            :title="'Sửa ghi chú cho phiếu: ' + stockSlipCode"
          >
            <b-row class="mb-5">
              <b-col>
                <label class="labelInput" for="inputOriginalPrice"
                  >Ghi chú</label
                >
                <b-form-textarea
                  v-model="note"
                  placeholder="Nhập ghi chú..."
                  rows="5"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="updateNote"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="handleShowNoteModel"
                  >Hủy</b-button
                >
              </b-col>
            </b-row>
          </b-modal>
        </template>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import moment from 'moment';
import { VueAutosuggest } from 'vue-autosuggest';
import { IMPORT_STOCK } from '../../../utils/constants';
import { vi } from 'vuejs-datepicker/dist/locale';
import { VclTable } from 'vue-content-loading';
import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
import fileDownload from '../../../utils/file-download';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import decounce from 'debounce';
import localData from '../../../utils/saveDataToLocal';
import Multiselect from 'vue-multiselect';
import axios from 'axios';
import { BASE_URL } from './../../../utils/constants';
import { xoa_dau } from './../../../utils/common';

export default {
  data() {
    return {
      dpForm: {
        startDate: moment().subtract(15, 'days').format('DD/MM/YYYY'),
        endDate: '',
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      disabledDates: {},
      format: 'dd-MM-yyyy',
      language: vi,
      btnCreate: {
        fontWeight: '600!important',
      },
      stocks: [],
      search: '',
      searchStock: '',
      searchId: '',
      currentPage: 0,
      totalPages: 1,
      count: 1,
      totalItems: 0,
      page: 1,
      status: '',
      store: '',
      searchFromDay: '',
      searchToDay: '',
      type: IMPORT_STOCK,
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '4%',
          },
          tdClass: 'sttClass',
        },
        {
          key: 'code',
          label: 'Mã phiếu nhập kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '16%' },
        },
        {
          key: 'relateStockSlipId',
          label: 'Từ hóa đơn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'sourceWarehouseId',
          label: 'Kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'providerName',
          label: 'Nhà cung cấp',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'countProduct',
          label: 'SL SP',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
        },
        {
          key: 'countQuantity',
          label: 'Tổng SL',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
        },
        {
          key: 'description',
          label: 'Nội dung',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'createBy',
          label: 'Người tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        { key: 'actions', label: '', thStyle: { width: '5%' } },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      selected: '',
      options: [
        {
          data: [],
        },
      ],
      filteredOptions: [],
      limit: 10,
      searchProduct: '',
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      optionsStore: [
        {
          data: [],
        },
      ],
      selectedCompany: null,
      listCompany: [],
      valueStore: [],
      selectedType: null,
      listType: [
        {
          id: 0,
          name: 'Tất cả',
        },
        {
          id: 1,
          name: 'Nhập NCC',
        },
        {
          id: 2,
          name: 'Nhập chuyển kho',
        },
        {
          id: 3,
          name: 'Nhập trả hàng',
        },
        {
          id: 4,
          name: 'Nhập chuyển hoàn',
        },
        {
          id: 5,
          name: 'Nhập thu cũ',
        },
      ],
      hoverPrice: false,
      stockSlipCode: '',
      showEditPriceModal: false,
      note: '',
      filteredOptionProvider: [],
      optionsProvider: [
        {
          data: [],
        },
      ],
      searchProvider: '',
      listProvider: [],
    };
  },
  methods: {
    onEndChange: function () {
      this.onFilter();
    },
    onStartChange: function () {
      this.onFilter();
    },
    fetchStore: async function () {
      // this.options[0].data = [];
      this.optionsStore = [];
      ApiService.setHeader();
      ApiService.get(
        `/stores/getStores?companyId=${this.selectedCompany}`
      ).then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let option = {
            code: element.id,
            name: element.shortName,
            checked: false,
          };
          this.optionsStore.push(option);
        });
        //this.filteredOptions = [{ data: this.options[0].data }];
      });
    },
    fetchStock: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      let listStoreId = [];
      this.optionsStore.forEach((element) => {
        if (element.checked) {
          listStoreId.push(element.code);
        }
      });
      if (this.searchProvider.trim() === '') {
        this.selectedProvider = null;
      }

      let params = {
        page: this.page,
        limit: 10,
        searchDesciption: this.search,
        searchCode: this.searchId,
        fromDate: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 00:00:00'
            )
          : '',
        toDate: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 23:59:59'
            )
          : '',
        type: this.type,
        searchProduct: this.searchProduct,
        storeIds: listStoreId,
        companyId: this.selectedCompany,
        mode: this.selectedType,
        providerId: this.selectedProvider,
      };

      ApiService.setHeader();
      ApiService.query('stock-slips', { params })
        .then((response) => {
          this.stocks = [];
          const stockResponse = response.data.data.data;
          this.totalPages = response.data.data.totalPage;
          this.currentPage = response.data.data.currentPage;
          this.totalItems = response.data.data.totalRow;
          let result = [];
          stockResponse.forEach((item, index) => {
            let stock = {
              count:
                this.currentPage >= 2
                  ? index + 1 + this.currentPage * 10 - 10
                  : index + 1,
              id: item.id,
              code: item.code,
              sourceWarehouseId: item.sourceWarehouseId,
              providerId: item.providerId,
              providerName: item.providerName,
              description: item.description,
              totalAmount: item.totalAmount,
              type: item.type,
              createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
              createBy: item.createBy,
              relateStockSlipId: item.relateStockSlipId,
              billType: item.billType,
              transferStockSlipId: item.transferStockSlipId,
              idNhanh: item.idNhanh,
              companyName: item.companyName,
              countProduct: item.countProduct,
              countQuantity: item.countQuantity,
            };
            result.push(stock);
            this.stocks = [...new Set(result)];
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    editItem: function (item) {
      this.$router.push({
        name: 'update-stock',
        query: { id: item.id },
      });
    },
    onSelected(option) {
      this.selected = option.item.name;
      this.searchStock = option.item.name;
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;
      // if (text === '' || text === undefined) {
      //   return;
      // }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
      //this.fetchStock();
    },
    /**
     * This is what the <input/> value is set to when you are selecting a suggestion.
     */
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa phiếu nhập kho!',
        text: 'Bạn có chắc muốn xóa phiếu nhập kho này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    deleteItem: async function (item) {
      let id = item.id;
      ApiService.setHeader();
      ApiService.delete(`stocks/delete-stock/${id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchStock();
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    linkToBill: function (item) {
      let nameRouter = 'list-return-bill';
      let query = { id: item.relateStockSlipId };
      if (item.billType === 5) {
        nameRouter = 'update-transfer-stock';
        query.id = item.transferStockSlipId;
      }
      this.$router.push({
        name: nameRouter,
        query: query,
      });
    },
    fetchProduct() {
      this.optionsProduct[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch?searchProduct=${this.searchProduct}&storeId=&includeParent=true`
      ).then(({ data }) => {
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
      });
    },
    debounceInput: decounce(function () {
      this.fetchProduct();
    }, 500),
    onSelectedProduct(option) {
      this.searchProduct = option.item.productName;
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInput();
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-stock',
      });
      this.fetchStock();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    onSelectStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStore[index].checked = true;
    },

    onRemoveStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStore[index].checked = false;
    },
    customLabelStore(option) {
      return `${option.name}`;
    },
    fetchCompany: async function () {
      this.listCompany = [];
      ApiService.setHeader();
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
        this.listCompany.unshift({
          id: null,
          name: 'Tất cả',
        });
      });
    },
    onChangeCompany() {
      this.valueStore = [];
      this.searchStock = '';
      this.fetchStore();
    },
    getLinkDoc(item) {
      return `#/stocks/update-stock?id=${item.id}`;
    },
    reportClick: async function () {
      let listId = [];
      this.optionsStore.forEach((element) => {
        if (element.checked) {
          listId.push(element.code);
        }
      });
      if (this.searchProvider.trim() === '') this.selectedProvider = null;
      let searchDesciption = this.search;
      let searchCode = this.searchId;
      let searchFromDay = this.dpForm.startDate
        ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      let searchToDay = this.dpForm.endDate
        ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      let searchProduct = this.searchProduct;
      let companyId = this.selectedCompany;
      let typeSearch = this.selectedType;
      let baseUrl = BASE_URL;

      let url = `${baseUrl}stocks/export-excel-stock-slip?type=${
        this.type
      }&providerId=${
        this.selectedProvider
      }&searchProduct=${searchProduct}&searchDesciption=${searchDesciption}&searchCode=${searchCode}&searchFromDay=${searchFromDay}&searchToDay=${searchToDay}&companyId=${companyId}&typeSearch=${typeSearch}&${listId
        .map((n, index) => `listStoreId[${index}]=${n}`)
        .join('&')}`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress: function(progressEvent) {
          console.log(progressEvent);
          // const dataChunk = progressEvent.currentTarget.response;
          // console.log(dataChunk);
        }
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    handleShowNoteModel(item) {
      this.showEditPriceModal = !this.showEditPriceModal;
      this.stockSlipCode = item.code;
      this.note = item.description;
    },
    updateNote() {
      let data = {
        stockSlipCode: this.stockSlipCode,
        description: this.note,
      };
      ApiService.setHeader();
      ApiService.post('stocks/update-stockslip-note', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.showEditPriceModal = !this.showEditPriceModal;
            this.fetchStock();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    onSelectedProvider(option) {
      this.searchProvider = option.item.name;
      this.selectedProvider = option.item.id;
      //this.onFilter();
    },
    onInputChangeProvider(text) {
      if (!text) {
        text = '';
      }
      this.searchProvider = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsProvider[0].data
        .filter((item) => {
          let itemName = xoa_dau(item.name);
          return itemName.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);

      this.filteredOptionProvider = [
        {
          data: filteredData,
        },
      ];
    },
    fetchProvider() {
      ApiService.setHeader();
      ApiService.get(`providers/getAll`).then(({ data }) => {
        this.listProvider = data.data.providers;
        let noneProvider = {
          id: null,
          name: 'Tất cả NCC',
        };
        this.listProvider.unshift(noneProvider);
        this.listProvider.map((element) => {
          this.optionsProvider[0].data.push(element);
        });
        this.filteredOptionProvider = [{ data: this.optionsProvider[0].data }];
      });
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    printItem: function (item) {
      this.$router.push({
        name: 'print-import-stockslip',
        query: { id: item.id },
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Nhập kho', route: 'stocks' },
      { title: 'Danh sách phiếu nhập kho' },
    ]);
  },
  created() {
    this.fetchStock();
    this.fetchStore();
    this.fetchCompany();
    this.fetchProvider();
  },
  components: {
    KTCodePreview,
    VueAutosuggest,
    VclTable,
    datePicker,
    Multiselect,
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

select {
  height: 2.5em;
}

.example {
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

/* h5 {
  font-size: 100%;
  padding: 0;
} */

.form-group {
  margin-bottom: 1em;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

.productCode:hover {
  text-decoration: underline;
}
.test {
  position: absolute;
  right: 1vw;
}
.checkbox-label {
  display: block;
}
.multiselect {
  min-height: initial;
}
.multiselect__tags {
  display: block;
  padding: 3px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 12px;
  min-height: initial;
}
.multiselect__element {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__input {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__single {
  font-size: 12px;
}
</style>
